import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { DatasetComponent } from "./dataset.component";

@Component({
    selector: 'editor-cell',
    template: `<input class="colourInput" type="button"
                      [cpUseRootViewContainer]="true"
                      [cpToggle]="true"
                      [cpOKButton]="true"
                      [cpOKButtonClass]="'text-black'"
                      [cpOutputFormat]="'hex'"
                      [cpAlphaChannel]="'disabled'"
                      [(ngModel)]="color"
                      [(colorPicker)]="color"
                      (colorPickerSelect)="okPressed($event)"
                      (colorPickerChange)="colorChanged($event)"
                      (colorPickerOpen)="colorPickerOpened($event)"
                      [style.color]="textColor"
                      [style.background]="color"/>`,
    styles: [
        `
            .colourInput {
                border-radius: 5px;
                padding: 10px 20px 10px 20px;
                text-align: center;
                display: inline-block;
                width: 100px;
                outline: none;
            }`
      ]
})

export class ColourPickerEditor implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public textColor: string = '#000f00'
    public color: string;
    public displayedColor: string;
    public context: DatasetComponent;

    @ViewChild('input', { read: ViewContainerRef }) public input: ViewContainerRef;

    ngAfterViewInit() {


    }

    agInit(params: any): void {
        this.params = params;
        this.context = params.context;

        this.color = this.params.value;
        if (this.color.length === 6 && !this.color.startsWith("#")) {
            this.color = `#${this.color}`;
        }
        this.colorChanged(this.color);
    }

    getValue() {
        return this.displayedColor;
    }

    isPopup(): boolean {
        return true;
    }

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
        return false;
    }

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
        this.params.context.colourItemEditingFinished();
        return false;

        // todo return (if this.color is not a valid colour)
        //return this.color isnotavalidcolour
    }

    colorChanged($event: string) {
        this.textColor = ColourPickerEditor.isLightColour($event) ? '#000000' : '#ffffff';
        this.displayedColor = $event.replace("#", "");
    }

    okPressed($event: string) {
        this.colorChanged($event);
        this.context.gridApi?.stopEditing(false);
    }

    static isLightColour(hexColour: string): boolean {
        const colour = this.hexToRgb(hexColour);
        if (!colour) return null;
        const hsp = Math.sqrt(
            0.299 * (colour.r * colour.r) +
            0.587 * (colour.g * colour.g) +
            0.114 * (colour.b * colour.b));
        return hsp > 127.5;
    }

    static hexToRgb(hex: string): { r:number, g:number, b:number } {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    colorPickerOpened($event: string) {
        if (!$event) {
            this.color = '#ffffff';
            this.colorChanged('#ffffff');
        }
    }
}
