import {Component} from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { DatasetComponent } from "./dataset.component";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DatasetItem } from "../datasets.types";


@Component({
    selector: 'distlist-cell-component',
    template: `

   `
})
export class EmptyCellRenderer {
    cellValue: boolean;
    editMode: boolean;
    datasetComponent: DatasetComponent;
    datasetItem: DatasetItem;

    constructor() {
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {

    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams) {

    }
}
